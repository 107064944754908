import { useNavigate } from 'react-router-dom';
import logo_black from '../../assets/images/LOGO_BLACK.png';
import close from '../../assets/images/svgs/close.svg';
import { Button } from '../../components/button';
import { changeScreen } from '../../store/auth/auth-action';
import { useAppDispatch } from '../../store/hooks';
import { useTranslation } from 'react-i18next';
import { getCurrentLanguagePath } from '../../translation/getCurrentLanguagePath';
import './styles.scss';
import CountryDropdown from '../../components/country-dropdown';
import { RootState } from '../../store';
import { setCountryId } from '../../store/country/country-action';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const StartScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const pathLang = getCurrentLanguagePath();

  const country_id = useSelector((state: RootState) => state.country.country_id) || '';
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    dispatch(setCountryId(''));
  }, [dispatch]);

  const _setvalue = (setter: (e: string) => void, e: string) => {
    dispatch(setCountryId(e));
  };

  const _closeModal = () => {
    navigate("/" + pathLang);
  };

  const _signUp = () => {
    if (!country_id) {
      setErrorMessage('Please select a country to proceed');
      return;
    }

    dispatch(changeScreen('Sign Up'));
  };

  const _signIn = () => {
    dispatch(changeScreen('Sign In'));
  };

const handleCountrySelectChange = (e: string) => {
    if (errorMessage) {
        setErrorMessage('')
    }
    _setvalue(setCountryId, e)
}

  return (
    <div className="container">
      <div className="container__close-wrapper">
        <img
          className="container-close"
          onClick={_closeModal}
          src={close}
        />
      </div>
      <div className="container__body">
        <div className="container__logo-container">
          <img src={logo_black} className="container_logo" />
        </div>
        <div className='container__cDropdown'>
          <CountryDropdown
            value={country_id}
            setValue={(e: string) => handleCountrySelectChange(e)}
            placeholderValue="Country of Residence"
            customClass={`start-screen-country-dropdown ${errorMessage ? 'error' : ''}`}
          />
          {errorMessage && (
            <p className="error-message">{errorMessage}</p>
          )}
        </div>
       
        <div className="container__sign-up-wrapper">
          <Button
            variant="white-outlined"
            text={t("sign_up")}
            onClick={_signUp}
          />
        </div>

        <p className="container_already-text">
          {t("already_have_an_account")}{' '}
          <span onClick={_signIn} className="container_sign-in">
            {t("log_in")}
          </span>
        </p>
      </div>
    </div>
  );
};

export default StartScreen;
