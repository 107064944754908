import { useState } from 'react';
import backIcon from '../../assets/images/svgs/arrow-black.svg';
import { useAppDispatch } from '../../store/hooks';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { changeScreen } from '../../store/auth/auth-action';
import MenuHeader from '../../components/menu-header';

const FAQ = () => {

    const sections = [
        {
            title: 'General',
            questions: [
                {
                    question: 'How can I save for my pension without making myself contributions?',
                    answer: 'We receive a marketing fee when we redirect you to a brand partner\'s website. This fee is used to make your pension contribution.'
                },
                {
                    question: 'What is the catch?',
                    answer: 'There is no catch! There are many cashback programs in the world which all work similarly as PAI. We just decided to tackle a difficult problem which is how to get people to save for their own pensions.'
                },
                {
                    question: 'How much will I get?',
                    answer: 'It depends with which partners you shop. We aim to get the best possible deals for you. The target is between 5 and 10% of your purchase.'
                },
                {
                    question: 'Is it enough for saving up to my pension?',
                    answer: 'It is complementary to your other pension savings, such as a state pension. From shopping alone you may not draw enough benefits in retirement, we want to help you start a habit. However, 50,000 EUR accumulated over your adult lifespan is certainly possible!'
                },
                {
                    question: 'Do I have to make investment decisions?',
                    answer: 'No, we have partnered with an all asset class fund, which is run by some of the best in the business. We automatically change the allocation to reduce risk as you near retirement. It is a worry-free contribution.'
                },
                {
                    question: 'Can I add more contributions?',
                    answer: 'Yes, you can always decide to contribute more to your private pension.'
                },
                {
                    question: 'Why are there programs in other countries than my own?',
                    answer: 'We believe you should be able to save for your pension on all your spending, including when traveling.'
                }
            ]
        },
        {
            title: 'Transactions',
            questions: [
                {
                    question: 'How long does it take before I can see my transaction?',
                    answer: 'It depends which affiliate network is used, some provide us the data within 15 mins, with others it can take some hours.'
                },
                {
                    question: 'I made a purchase but my transaction doesn’t show?',
                    answer: [
                        'Does your browser or phone have any anti-tracking settings?',
                        'Did you accept the cookies on the site when you entered?',
                        'Sometimes sales items are excluded from the commission, we do not get the transaction in these cases.',
                        'It is possible you visited the site from a different affiliate link, in which case we may not get the transaction data.'
                    ]as string[],
                },
                {
                    question: 'The commission is lower than I expected?',
                    answer: 'The retailers often have different product groups with different commission amounts. We state “up to” the percentage you can expect. It can also be lower.'
                },
                {
                    question: 'How do I get a missing transaction recorded which I believe is valid?',
                    answer: 'You can use the feedback option or contact us on: info@pai.socal. However, in order to give the largest possible share of valid transactions to our users, we are not able to spend a lot of resources on missing transactions. We will work on automating this in the near future.'
                }
            ]
        },
        {
            title: 'Pension account',
            questions: [
                {
                    question: 'When can I open my pension savings account?',
                    answer: 'We are currently working on the integration, which should be complete prior to the end of January 2025 for Swedish savers. However, you can already accumulate rewards which will be deposited once the pension account has been set up.'
                },
                {
                    question: 'I do not live in Sweden, can I open a pension account?',
                    answer: 'Not right now, we are hard at work expanding our offering to other countries.'
                },
                {
                    question: 'Why does it take so long before my rewards are contributed to my pension?',
                    answer: 'Because of statutory and policy reasons you can return goods for a long time. Therefore the commission we receive can take 90-120 days before we get paid. We cannot pay into your pension before we are paid.'
                },
                {
                    question: 'Where do I find out more information about how the pension is invested?',
                    answer: 'Once your pension account is set up and connected with us, you can find more details about the fund.'
                }
            ]
        },
        {
            title: 'Ambassador',
            questions: [
                {
                    question: 'What does it mean to be an ambassador?',
                    answer: 'You and all other users of PAI social can become ambassadors of PAI. For referrals and other activities you will then be rewarded with more pension contributions.'
                },
                {
                    question: 'How do I become an ambassador?',
                    answer: 'Simply share your referral link and once it has been used for a signup you are an active ambassador.'
                },
                {
                    question: 'What rewards do I get as an ambassador?',
                    answer: 'You are rewarded on the transactions made by those who used your link as well as for other actions.'
                }
            ]
        }
    ];

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState<Record<string, boolean>>({});
    const [showInfo, setShowInfo] = useState(false);

    const toggleAnswer = (index: string) => {
        setExpanded((prev) => ({
            ...prev,
            [index]: !prev[index], 
        }));
    };

    const _goBack = () => {
        dispatch(changeScreen('Menu Screen'));
    };

    const _showInfoModal = () => {
        setShowInfo(true);
    };

    const _hideInfoModal = () => {
        setShowInfo(false);
    };

    return (
        <div className="faq-container">
    <MenuHeader
        title={t('faq').toUpperCase()}
      />
            <div className="faq__container">
                {sections.map((section, sectionIndex) => (
                    <div key={sectionIndex} className="faq__section">
                        <h2 className="faq__section-title">{section.title}</h2>
                        {section.questions.map((q, questionIndex) => {
                            const questionId = `${sectionIndex}-${questionIndex}`;
                            return (
                                <div key={questionId} className="faq__item">
                                    <p
                                        className="faq__question"
                                        onClick={() => toggleAnswer(questionId)}
                                    >
                                        {q.question}
                                    </p>
                                    {expanded[questionId] && (
                                        <ul className="faq__answer">
                                            {Array.isArray(q.answer) ? (
                                                q.answer.map((sentence, index) => (
                                                    <li key={index}>{sentence}</li>
                                                ))
                                            ) : (
                                                <p>{q.answer}</p>
                                            )}
                                        </ul>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>

        </div>
    );
};

export default FAQ;