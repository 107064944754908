import React from 'react';
import backIcon from '../../assets/images/svgs/arrow-black.svg';
import infoIcon from '../../assets/images/svgs/info.svg';
import { useAppDispatch } from '../../store/hooks';
import { changeScreen } from '../../store/auth/auth-action';
import { useTranslation } from 'react-i18next';
import './styles.scss';

interface HeaderProps {
  title: string;
  showInfoButton?: boolean;
  onInfoClick?: () => void;
}

const MenuHeader: React.FC<HeaderProps> = ({ title, showInfoButton = false, onInfoClick }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const _goBack = () => {
    dispatch(changeScreen('Menu Screen'));
  };

  return (
    <div className="header-container">
      <img
        className="header-container__arrow"
        src={backIcon}
        onClick={_goBack}
        alt={t('Go Back')}
      />
      <h2 className="header-container__title">{title}</h2>
      
        <div
          className="header-container__info"
          onClick={onInfoClick}
        >
            {showInfoButton && (
          <img src={infoIcon} alt={t('Info')} />
        )}
        </div>
     
    </div>
  );
};

export default MenuHeader;
