import moment from 'moment'
import { useEffect, useState } from 'react'
import backIcon from '../../assets/images/svgs/arrow-black.svg'
import info from '../../assets/images/svgs/info.svg'
import TransactionInfo from '../../components/transaction-info'
import { TransactionDateContainer } from '../../components/transactions-date-container'
import { getTransactions } from '../../services/api'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { Transaction } from '../../types/api'
import { useTranslation } from 'react-i18next'
import './styles.scss'
import MenuHeader from '../../components/menu-header'

const TransactionScreen = () => {
    const { access_token } = useAppSelector((state) => state.auth)
    const { transactions } = useAppSelector((state) => state.transactions)
    const points = useAppSelector(
        (store) => store.auth.user?.loyalty_account.detailed
    )
    const dispatch = useAppDispatch()
    const [transactionsDate, setTransactionsDate] = useState<any>([])
    const [showInfo, setShowInfo] = useState(false)
    const {t} = useTranslation()

    useEffect(() => {
        const dates: any = new Set()
        transactions.map((el: Transaction) => {
            dates.add(moment(el.date).format('DD/MM/YYYY'))
        })
        setTransactionsDate([...dates])
    }, [transactions])

    useEffect(() => {
        getTransactions(access_token.access_token)
    }, [])

    const _goBack = () => {
        dispatch(changeScreen('Menu Screen'))
    }

    const _showInfoModal = () => {
        setShowInfo(true)
    }

    const _hideInfoModal = () => {
        setShowInfo(false)
    }
    return (
        <div className="transaction-container">
            <MenuHeader
            title={t('my_transactions')}
            showInfoButton
            onInfoClick={_showInfoModal}
      />
            <div className="transaction-balance">
                <div className="transaction-balance__info">
                    <span className="transaction-balance__type">
                        {t("points_earned")}:
                    </span>
                    <strong className="transaction-balance__amount">
                        {Number(points?.points_earned)/100}
                    </strong>
                </div>
                <div className="transaction-balance__info">
                    <span className="transaction-balance__type">
                    {t("points_cleared")}:
                    </span>
                    <strong className="transaction-balance__amount">
                        {Number(points?.points_cleared)/ 100}
                    </strong>
                </div>
            </div>
            <div className="transaction-container__content">
                {transactionsDate?.map((el: string, index: number) => (
                    <TransactionDateContainer
                        key={index}
                        date={el}
                        elements={transactions.filter(
                            (item: Transaction) =>
                                moment(item.date).format('DD/MM/YYYY') === el &&
                                item
                        )}
                    />
                ))}
            </div>
            {showInfo && <TransactionInfo hideInfo={_hideInfoModal} />}
        </div>
    )
}

export default TransactionScreen
