import { NavigateFunction } from 'react-router-dom';  
import { getCurrentLanguagePath } from '../translation/getCurrentLanguagePath';

export const handleSiteClick = (
    link: string, 
    isAuthenticated: boolean, 
    navigate: NavigateFunction  
) => {
    if (!link) {
        console.error('No valid link found');
        return;
    }
    const pathLang = getCurrentLanguagePath();  
    const formattedLink =
        link.startsWith('http://') || link.startsWith('https://')
            ? link
            : `https://${link}`;

    console.log(`Redirecting to: ${formattedLink}`);

    if (isAuthenticated) {
        window.open(formattedLink, '_blank', 'noopener,noreferrer');
    } else {
        navigate(`${pathLang}/menu`);
    }
};
