import Corporate_Details from '../screens/corporate-details'
import CorporateDetailsSlider from '../screens/corporate-details-slider'
import EditProfile from '../screens/edit-profile'
import FinishSignUp from '../screens/finish-sign-up'
import Following from '../screens/following'
import ForgotPassword from '../screens/forgot-password'
import MenuScreen from '../screens/menu-screen'
import MyImpact from '../screens/my-impact'
import ProjectDetails from '../screens/project-details'
import SelectInterest from '../screens/select-interest'
import Settings from '../screens/settings-screen'
import SignIn from '../screens/sign-in'
import SignUp from '../screens/sign-up'
import SponsorScreen from '../screens/sponsor-screen'
import StartScreen from '../screens/start-screen'
import TermsOfUse from '../screens/terms-of-use'
import ThanksForDonate from '../screens/thanks-for-donate'
import TransactionScreen from '../screens/transaction-screen'
import CalculatingIncome from '../screens/calculating-income'
import AmbassadorScreen from '../screens/ambassador'
import FAQ from '../screens/faq'

export const _renderScreen = (screen: string) => {
    switch (screen) {
        case 'Start screen':
            return <StartScreen />
        case 'Sign Up':
            return <SignUp />
        case 'Sign In':
            return <SignIn />
        case 'Finish Sign Up':
            return <FinishSignUp />
        case 'Select Interest':
            return <SelectInterest />
        case 'Forgot Password':
            return <ForgotPassword />
        case 'Menu Screen':
            return <MenuScreen />
        case 'My Impact':
            return <MyImpact />
        case 'Project Details':
            return <ProjectDetails />
        case 'Project Sponsor':
            return <SponsorScreen />
        case 'Thanks for donate':
            return <ThanksForDonate />
        case 'Settings':
            return <Settings />
        case 'Calculating income':
            return <CalculatingIncome />
        case 'Edit Profile':
            return <EditProfile />
        case 'Transaction Screen':
            return <TransactionScreen />
        case 'Terms Of Use':
            return <TermsOfUse />
        case 'Following':
            return <Following />
        case 'Corporate Details':
            return <Corporate_Details />
        case 'Corporate Details Slider':
            return <CorporateDetailsSlider />
        case 'Ambassador':
            return <AmbassadorScreen />
        case 'FAQ':
            return <FAQ />
        default:
            return <StartScreen />
    }
}
