import React, { useState } from 'react';
import { Country } from '../../types/api';
import './styles.scss';

interface CountryDropdownProps {
  value: string;
  setValue: (value: string) => void;
  placeholderValue: string;
  customClass?: string;
}

export const countries: Country[] = [
  {
    alpha2: "SE",
    alpha3: "SWE",
    currency: "SEK",
    id: "b5dc99e0-4f55-4f8b-a844-068d741c11b8",
    name: "Sweden",
    numeric: 752,
  },
  {
    alpha2: "NL",
    alpha3: "NLD",
    currency: "EUR",
    id: "2649e1cb-fc92-4750-b52c-5a29b494ccc9",
    name: "Netherlands",
    numeric: 528,
  },
];

const CountryDropdown: React.FC<CountryDropdownProps> = ({
  value,
  setValue,
  placeholderValue,
  customClass
}) => {
  const [isListActive, setIsListActive] = useState<boolean>(false);

// call getCountries API from server (for the future development)

//   useEffect(() => {
//     const fetchCountries = async () => {
//       try {
//         const { response, data } = await getCountries(); 
//         if (response.status === 200) {
//           setCountries(data); 
//         } else {
//           setError('Failed to fetch countries');
//         }
//       } catch (err) {
//         setError('An error occurred while fetching countries');
//         console.error(err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchCountries(); 
//   }, []);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div style={{ color: 'red' }}>{error}</div>;
//   }

const handleDropdownClick = () => {
  setIsListActive(!isListActive);
};
  const truncatedPlaceholder =
    placeholderValue.length > 25
      ? `${placeholderValue.slice(0, 25)}...`
      : placeholderValue;

      return (
        <div className={`dropdown_container ${customClass}`}>
          <label className="dropdown_container__label" htmlFor="country-select">
            {truncatedPlaceholder}
          </label>
          <div className="dropdown_container__input-wrapper">
              <select
                required
                id="country-select"
                className="dropdown_container__input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              >
                <option hidden disabled selected value="">Select a country</option>
                {countries.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </select>
          </div>
        </div>
      );
    };
    
    export default CountryDropdown;