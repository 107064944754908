import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Plus from '../../assets/images/svgs/PlusForBrand.svg'
import Unfollow from '../../assets/images/svgs/unfollowPopUp.svg'
import SettingsHeader from '../../components/settings-header'
import { getFollowings, unfollowFeatures } from '../../services/api'
import { changeScreen } from '../../store/auth/auth-action'
import {
    setFollowings,
    setIsInScreen,
    _Unfollow,
} from '../../store/following/following-action'
import { Followings } from '../../store/following/types'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setActiveCorporateId } from '../../store/impact/impact-action'
import { useTranslation } from 'react-i18next'
import Spinner from '../../components/spinner'
import FollowingCard from '../../components/following-card'
import { getCurrentLanguagePath } from '../../translation/getCurrentLanguagePath'

import './styles.scss'
import { Country } from '../../types/api'
import MenuHeader from '../../components/menu-header'

const Following = () => {
    const dispatch = useAppDispatch()
    const { followings } = useAppSelector((state) => state.following)
    const { access_token } = useAppSelector((state) => state.auth)
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const pathLang = getCurrentLanguagePath()

    const _closeModal = () => {
        navigate(pathLang + '/')
    }
    const _getFollowings = async () => {
        setLoading(true)
        const data1 = await getFollowings(access_token.access_token, 0, 0)
        const data2 = await getFollowings(
            access_token.access_token,
            data1.metadata.total,
            0
        )
        dispatch(setFollowings(data2))
        setLoading(false)
    }
    const _unfollow = async (id: string) => {
        await unfollowFeatures(access_token.access_token, id)
        dispatch(_Unfollow(id))
        _getFollowings()
    }
    const _openDetails = (id: string, name: string, country: Country) => {


        const countryValue: string = country?.name
            ? `__${country.alpha3}`
            : ``

        dispatch(setIsInScreen(true))
        dispatch(setActiveCorporateId(id))
        dispatch(changeScreen('Corporate Details'))
        navigate('/brand/' + name.replaceAll(' ', '_') + countryValue)
    }
    useEffect(() => {
        dispatch(setFollowings({} as Followings))
        _getFollowings()
    }, [])

    if (loading) return <Spinner />

    return (
        <div id="scrollableDiv" className="follow-screen">
            <MenuHeader
        title={t('following').toUpperCase()}
      />
            <p className="follow-screen_count">
                {t('you_have')}{' '}
                {followings?.metadata?.total ? followings?.metadata?.total : 0}{' '}
                {t('saved_brands')}
            </p>
            <div id="scrollableDiv" className="follow-screen__container-global">
                <div id="scrollableDiv" className="follow-screen__container">
                    {followings?.results?.map((e: any) => {
                        return (
                            <FollowingCard
                                hideBackground={e.hide_background_image}
                                hideLogo={e.hide_logo}
                                hideName={e.hide_name}
                                logo={e.logo}
                                id={e.id}
                                country={e.country}
                                _openDetails={_openDetails}
                                name={e.name}
                                photo={e.photo}
                                _unfollow={_unfollow}
                                key={e.id}
                            />
                        )
                    })}
                    <div
                        className="follow-screen__new-brand"
                        onClick={_closeModal}
                    >
                        <p className="follow-screen__new-brand_title">
                            {t('add_new_brand')}
                        </p>
                        <img
                            className="follow-screen__new-brand_plus"
                            src={Plus}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Following
