import { useRef, useState } from 'react'
import EditProfile_Icon from '../../assets/images/svgs/edit-profile.svg'
import { Button } from '../../components/button'
import SettingsHeader from '../../components/settings-header'
import TextField from '../../components/text-field'
import { editOwnProfile, uploadFile } from '../../services/api'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { File, User } from '../../types/api'
import { validateEmail } from '../../utils/_validateEmail'
import { useTranslation } from 'react-i18next'
import './styles.scss'
import CountryDropdown, { countries } from '../../components/country-dropdown';

const EditProfile = () => {
    const dispatch = useAppDispatch()
    const user = useAppSelector((state) => state.auth.user) as User
    const { access_token } = useAppSelector((state) => state.auth)
    const {t} = useTranslation()
    const hiddenFileInput = useRef<any | null>(null)

    const [email, setEmail] = useState(user.email)
    const [first_name, setFirst_name] = useState(user.first_name)
    const [last_name, setLast_name] = useState(user.last_name)
    const [country_id, setCountry_id] = useState(user.country.id || "")
    const [userCurrency, setUserCurrency] = useState(user.currency || "")
    const [profile_photo, setProfile_photo] = useState<string>(
        user?.profile_photo?.url
    )
    const [imageId, setImageId] = useState('')
    const [error, setError] = useState('')

    
    const _save = async () => {
        if (!email.trim() || !first_name.trim() || !last_name.trim()) {
            setError('Please fill all fields')
        } else if (!validateEmail(email)) {
            setError('Incorrect email')
        } else {
            
            let data: any = {
                first_name: first_name,
                last_name: last_name,
                country_id: country_id,
                currency: userCurrency,
                email: email,
                edit_version: user.edit_version,
            }
            console.log(data)
            if (imageId) {
                data = { ...data, profile_photo_id: imageId }
            }
            const response = await editOwnProfile(data, access_token)
            if (!response.violations) {
                dispatch(changeScreen('Menu Screen'))
            } else {
                setError(response.violations[0].message)
            }
        }
    }

    const onChangeFirstName = (e: string) => {
        setFirst_name(e)
        setError('')
    }

    const onChangeLastName = (e: string) => {
        setLast_name(e)
        setError('')
    }

    const onChangeEmail = (e: string) => {
        setEmail(e)
        setError('')
    }
    const onChangeCountry = (e: string) => {
        setCountry_id(e);
        const selectedCountry = countries.find(country => country.id === e);
    
        if (selectedCountry) {
            setUserCurrency(selectedCountry.currency);
            console.log('selectedCountry.currency: ' + selectedCountry.currency)
            console.log('userCurrency :' + userCurrency)
        } else {
            console.error("Selected country not found in the list");
        }
    
        setError('');
    };

    const _onUploadFile = async (e: any) => {
        setError('')
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', e.target.files[0], file.name)
        formData.append('mime', 'not_validate')
        const data = (await uploadFile(
            formData,
            access_token.access_token,
            setError
        )) as File
        if (data.id) {
            setImageId(data.id)
        }
        setProfile_photo(URL.createObjectURL(file))
    }

    const _handleClick = () => {
        hiddenFileInput.current.click()
    }
    return (
        <div className="edit-profile-container">
            <SettingsHeader screen="Settings" text={t("edit_profile").toUpperCase()} />
            <div className="edit-profile-container__photo_edit-photo">
                <img
                    src={profile_photo}
                    className="edit-profile-container__photo"
                />
                <div
                    className="edit-profile-container__edit-photo"
                    onClick={_handleClick}
                >
                    <div className="edit-profile-container__img">
                        <img src={EditProfile_Icon} />
                    </div>
                    <div className="edit-profile-container__text">
                       {t("change_photo")}
                    </div>
                    <input
                        type="file"
                        onChange={_onUploadFile}
                        ref={hiddenFileInput}
                        style={{ display: 'none' }}
                        accept="image/*"
                    />
                </div>
            </div>
            <div className="edit-profile-container__inputs">
                <TextField value={first_name} setValue={onChangeFirstName} />
                <TextField value={last_name} setValue={onChangeLastName} />
                <CountryDropdown
                    value={country_id}
                    setValue={onChangeCountry}
                    placeholderValue='Country of Residence'
                    customClass={`edit-profile-country-dropdown`}
                />
                <TextField value={email} setValue={onChangeEmail} />
            
                {error && (
                    <p style={{ color: 'red', fontWeight: 'bold' }}>{error}</p>
                )}
            </div>
            <div className="edit-profile-container-button__container">
                <Button
                    variant="white-outlined"
                    text={t("save")}
                    onClick={_save}
                    disabled={false}
                />
            </div>
        </div>
    )
}

export default EditProfile
