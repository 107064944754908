import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss'
import { Button } from '../button';

interface Props {
    hideInfo: () => void;
}

const AmbassadorInfo: React.FC<Props> = ({ hideInfo }) => {
    const { t } = useTranslation();

    return (
        <div className="ambassador-info-container">
            <div className="ambassador-info-container__modal">
                <h3 className="ambassador-info-container__title">
                    {t('ambassador program')}
                </h3>
                <p className="ambassador-info-container__desc">
                    {t(`Generate your personal referal link, share it with friends 
                        and receive special ambassador bonuses!`)}
                </p>
                <div className="ambassador-info-container__button-wrapper">
                    <Button variant="white-outlined" text={t('close')} onClick={hideInfo} />
                </div>
            </div>
        </div>
    );
};

export default AmbassadorInfo;
