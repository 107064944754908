import { useState } from 'react';
import AmbassadorInfo from '../../components/ambassador-info';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { changeScreen } from '../../store/auth/auth-action';
import { Button } from '../../components/button';
import { User } from '../../types/api';
import MenuHeader from '../../components/menu-header';
import Toast from '../../components/toast';
import CustomModal from '../../components/custom-modal';
import { ReactComponent as FacebookSVG } from '../../assets/images/svgs/facebook.svg';
import { ReactComponent as X } from '../../assets/images/svgs/twitter-x.svg';
import { ReactComponent as Messenger } from '../../assets/images/svgs/facebook-messenger.svg';
import { ReactComponent as Whatsapp } from '../../assets/images/svgs/whatsapp.svg';
import { ReactComponent as Email } from '../../assets/images/svgs/email.svg';
import { ReactComponent as Threads } from '../../assets/images/svgs/threads.svg';
import { ReactComponent as Link } from '../../assets/images/svgs/link.svg';

const AmbassadorScreen: React.FC = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.user) as User;
    const [showInfo, setShowInfo] = useState(false);
    const [showShareOptions, setShowShareOptions] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const { t } = useTranslation();

    const _goBack = () => {
        dispatch(changeScreen('Menu Screen'));
    };

    const _showInfoModal = () => {
        setShowInfo(true);
    };

    const _hideInfoModal = () => {
        setShowInfo(false);
    };

    const _shareLink = () => {
        const linkToShare = user.ambassador_link;
        if (!linkToShare) {
            console.warn('No link available to share.');
            return;
        }
    
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
        if (isMobile && navigator.share) {
            navigator
                .share({
                    title: t('Ambassador program'),
                    text: t('Join the Ambassador program and earn rewards!'),
                    url: linkToShare,
                })
                .then(() => console.log('Link shared successfully'))
                .catch((err) => console.error('Error sharing the link:', err));
        } else {
            setShowShareOptions(true);
        }
    };
    

    const _copyToClipboard = () => {
        const linkToCopy = user.ambassador_link;
        if (linkToCopy) {
            navigator.clipboard
                .writeText(linkToCopy)
                .then(() => {
                    console.log('Copied to clipboard:', linkToCopy);
                    setShowToast(true); 
                    setTimeout(() => setShowToast(false), 3000); 
                })
                .catch((err) => {
                    console.error('Failed to copy text to clipboard:', err);
                });
        }
    };

    const _hideShareOptions = () => {
        setShowShareOptions(false);
    };

    const shareOptions = [
        {
            key: 'twitter',
            icon: <X />,
            label: t('Share on X'),
            action: () => window.open(`https://twitter.com/share?url=${user.ambassador_link}`, '_blank'),
        },
        {
            key: 'facebook',
            icon: <FacebookSVG />,
            label: t('Share on Facebook'),
            action: () => window.open(`https://www.facebook.com/sharer/sharer.php?u=${user.ambassador_link}`, '_blank'),
        },
        {
            key: 'messenger',
            icon: <Messenger />,
            label: t('Share on Messenger'),
            action: () => window.open(`https://www.messenger.com/t/?link=${user.ambassador_link}`, '_blank'),
        },
        {
            key: 'whatsapp',
            icon: <Whatsapp />,
            label: t('Share on WhatsApp'),
            action: () => window.open(`https://wa.me/?text=${user.ambassador_link}`, '_blank'),
        },
        {
            key: 'email',
            icon: <Email />,
            label: t('Share via Email'),
            action: () =>
                (window.location.href = `mailto:?subject=${t('Check out this referral link')}&body=${user.ambassador_link}`),
        },
        {
            key: 'threads',
            icon: <Threads />,
            label: t('Share on Threads'),
            action: () => window.open(`https://threads.net/intent/post?text=${user.ambassador_link}`, '_blank'),
        },
        {
            key: 'copy',
            icon: <Link />,
            label: t('Copy to Clipboard'),
            action: _copyToClipboard,
        },
    ];

    return (
        <div className="ambassador-container">
            <MenuHeader title={t('Ambassador program')} onInfoClick={_showInfoModal} />
            <div className="ambassador-container__description">
                <strong>Everyone can now become a PAI ambassador!</strong>
                <p>
                    You can simply create your own personal ambassador link. If your friends and family sign up using
                    your link, you will receive a variety of ambassador benefits.
                </p>
                <p>
                    The more people you help improve their pension, the higher your personal pension rewards will be.
                    You will receive a bonus pension contribution every time someone you referred shops via PAI!
                </p>
            </div>
            <div className="ambassador-container__action">
                <Button
                    variant="gasoline-outlined"
                    text={t('SHARE YOUR REFERRAL LINK')}
                    onClick={_shareLink}
                />
            </div>

            {showInfo && <AmbassadorInfo hideInfo={_hideInfoModal} />}
            {showShareOptions && (
            <CustomModal isOpen={showShareOptions} onClose={_hideShareOptions}>
                <div className="share-options">
                    <h3 className="share-options__header">{t('Share your referral link')}</h3>
                    {shareOptions.map((option) => (
                        <div
                            key={option.key}
                            className="share-options__option"
                            onClick={option.action}
                        >
                            {option.icon} {option.label}
                        </div>
                    ))}
                </div>
            </CustomModal>
        )}
        {showToast && (
            <Toast
                message={t('Link copied to clipboard!')}
                onClose={() => setShowToast(false)}
            />
        )}
    </div>
);
};

export default AmbassadorScreen;
