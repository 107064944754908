import React, { useEffect } from 'react';
import './styles.scss';

interface ToastProps {
    message: string;
    duration?: number; 
    onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({ message, duration = 1900, onClose }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, duration);

        return () => clearTimeout(timer);
    }, [duration, onClose]);

    return (
        <div className="toast">
            <span>{message}</span>
        </div>
    );
};

export default Toast;
